* {
  box-sizing: border-box;
}
.competitors {
  padding: 2%;
  margin: 0 auto;
  background: #263238;
}

small {
  font-size: 12px;
  margin: 0;
}
.analysis__form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 4%;
}
.analysis__form input {
  height: 40px;
  border: 0.1px solid grey;
  padding-left: 2%;
  background-color: rgb(255 255 255);
}
.analysis__form input:focus {
  outline: none;
  border-radius: 0; /* Removes rounding of corners */
  box-shadow: none; /* Removes any shadow effects */
  background-color: rgb(214 225 255);
}

.account__form {
  display: flex;
  flex-direction: column;
}

.account__form input {
  margin-bottom: 2%;
}

.login--btn {
  color: black;
  padding: 1%;
  border: 1px solid rgb(255, 255, 255);
  background: white;
}

.small__descriptive {
  color: #b7b7b7;
  margin-top: 6%;
}

.account__form h2 {
  font-size: 18px;
  letter-spacing: 10px;
  text-transform: uppercase;
  color: white;
}
.gpts__iframe {
  margin-top: 10%;
  border-radius: 10px;
}
.data__composer {
  background: rgba(240, 240, 240, 0.66);
  padding: 4%;
  border-radius: 20px;
}
.hero {
  margin-top: 20%;
}

.hero h1 {
}
.error {
  color: rgb(233, 112, 112);
  border: none;
  font-size: 13px;
  padding-top: 0px;
  color: black;
}

.error p {
  padding: 0;
  margin: 0;
  color: #ff903b;
}
.success {
  color: rgb(0, 125, 40);
  border: none;
  font-size: 13px;
  padding-top: 0px;
}

.hero h4 {
  margin: 0;
  line-height: 2rem;
  font-size: 28px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.competitor__form h2 {
  letter-spacing: 10px;
  text-transform: uppercase;
  line-height: 25px;
  font-size: 18px;
  color: white;
}
.competitor__form img {
  width: 5%;
}

.competitors__form h2 {
  letter-spacing: 10px;
  text-transform: uppercase;
  line-height: 25px;
  font-size: 18px;
}

.competitors__form img {
  width: 5%;
}

.add__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  margin-top: 1%;
}
.add__button img {
  width: 5%;
}
.add__button p {
  display: flex;
  margin: 0;
}
.get__button {
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
  width: 100%;
}
.get__button button {
  height: 60px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  transition: transform 0.2s ease;
  border-radius: 5px;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -4.5px;
  font-weight: 700;
  border: white solid 4px;
  border-radius: 4px;
  width: 60%;
  border: 1px solid black;
}

.get__button button:hover {
  transform: scale(0.98);
}
.get__button p {
  color: #c7d8dd;
}

.loading-bar {
  height: 50%;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  transition: width 0.1s ease-in-out;

  border-radius: 4px;
}
.footer__button:hover {
  transform: scale(0.98);
}

.competitors__div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.competitors__div img {
  cursor: pointer;
  height: 4%;
  margin-right: 4%;
}

.get__button button:disabled {
  cursor: default;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  /* Center the spinner with absolute positioning */
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.competitors__div input {
  width: 85%;
  flex-grow: 1;
}
.competitor-comparison {
  border-collapse: collapse;
  margin-bottom: 10%;
  transition: 0.5s ease-in-out;
}

.competitor-comparison h3 {
  margin: 0;
}

.competitor-comparison table {
  width: 100%;
  border-collapse: collapse;
}
.table__desc {
  text-align: left !important;
}

.competitor-comparison th,
.competitor-comparison td {
  border: 1px solid #e0e0e0;
  padding: 2% 3%;
  text-align: center;
  font-weight: 100;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.username__td {
  width: 10%;
  text-align: left !important;
}

.followers {
  margin-top: 1%;
  background: white;
  padding: 3%;
  border-radius: 4px;
  color: #202125;
  box-shadow: #256b704d 1px 1px 20px 0px;
}

.competitor-calculator {
  padding: 2%;
  background: #124c50b3;
  border-radius: 10px;
}
.competitor-calculator h1 {
  text-align: center;
}
.calculator {
  margin-top: 1%;
  background: #ffffff;
  padding: 3%;
  border-radius: 4px;
  color: rgb(71, 71, 71);
  box-shadow: #256b704d 1px 1px 20px 0px;
}

.calculator__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calculator__header h2 {
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quality {
  cursor: pointer;
}
.quality:hover {
  transform: scale(0.98);
}
.media__slider {
  margin-top: 15px;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: linear-gradient(
    93deg,
    #b7a8fc88,
    #bcf8ea71,
    #d9efdc7c,
    #b7a8fc88,
    #e7c5e780,
    #bce2ff8e
  );
  border-radius: 10px;
  outline: none; /* Remove outline */
}

.media__slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/images.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.media__slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/images.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.quality__slider {
  margin-top: 15px;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: linear-gradient(
    93deg,
    #b7a8fc88,
    #bcf8ea71,
    #d9efdc7c,
    #b7a8fc88,
    #e7c5e780,
    #bce2ff8e
  );
  border-radius: 10px;
  outline: none; /* Remove outline */
}

.quality__slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/bolt-lightning-solid.svg") no-repeat center
    center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.quality__slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/bolt-lightning-solid.svg") no-repeat center
    center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.gauge {
  position: relative;
  width: 100px;
  height: 50px; /* Adjust as needed */
  background: #ddd; /* Adjust as needed */
  border-radius: 100px 100px 0 0; /* Makes a half-circle */
}
.gauge__selector {
  width: 100px;
}

.pointer {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 2px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  background: blue; /* Adjust as needed */
  transform-origin: bottom;
  transform: rotate(-45deg);
}
/* Additional CSS for positioning the labels */
.label-container {
  position: relative;
  height: 10px;
  gap: 2rem; /* Adjust as needed */
  width: 100px; /* Should match the gauge width */
}

.label {
  position: absolute;
  white-space: nowrap; /* Keep the text on one line */
  font-size: 12px; /* Size of the text */
  transform-origin: bottom center; /* Pivot point for rotation */
}

.label-default {
  transform: rotate(308deg);
  left: -14px;
  bottom: -10px;
}

.label-better {
  left: 50%;
  bottom: 13px; /* Adjust as needed */
  transform: translateX(-50%);
}

.label-best {
  transform: rotate(56deg);
  right: -8px;
  bottom: -10px;
}

.lonley__date {
  justify-content: flex-end !important;
}
.multiplier__selector {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
}
.slider__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.slider__container h2 {
  color: #396a6d;
}

.quality__selector {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.followers__td {
  width: 16%;
  max-width: 70px;
}
.sort {
  background: #d1e0e4 !important;
  transition: 0.3s ease;
  cursor: pointer;
}
.sort:hover {
  background: #cad4d7 !important;
}
.competitor-comparison th {
  background-color: #f5f5f5;
}

.competitor-comparison td {
  vertical-align: middle;
}

.competitor-comparison .bar {
  height: 10px;
  background-color: #007aff;
  border-radius: 10px;
}

.competitor-comparison span span {
  background-color: #007aff; /* Change this to your desired color */
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 0.8em;
}

.insights__data img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.insights__data a {
  color: #007bff;
  text-decoration: none;
}

.insights__data a:hover {
  text-decoration: underline;
}

.account__stats {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.media-bar {
  height: 50%;
  left: 0;
  display: flex;
  width: 100%;
  transition: width 0.5s ease-out, background-color 0.5s ease-out;
}

.media-bar-holder {
  height: 15px;
  overflow: hidden;
  padding: 2%;
}

.engagement__th {
  margin-left: 4%;
}

.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date__range {
  margin-right: 2%;
}

.date__range select {
  padding: 4%;
  border: 0.1px solid #585858;
  border-radius: 2px;
  color: #585858;
  font-size: 12px;
}
.photos {
  background: orange;
  height: 10px;
  transition: width 0.5s ease-out, background-color 0.5s ease-out;
}

.carousels {
  background: pink;
  height: 10px;
  transition: width 0.5s ease-out, background-color 0.5s ease-out;
}
.reels {
  background: purple;
  height: 10px;
  transition: width 0.5s ease-out, background-color 0.5s ease-out;
}

.color__media {
  display: flex;
  gap: 1rem;
}
.color__media p {
  margin: 0;
  font-size: 12px;
}
.color__media p::before {
  content: "";
  display: inline-block;
  width: 10px; /* Size of the square */
  height: 10px; /* Size of the square */
  border-radius: 50%;
  margin-right: 5px; /* Space between the square and the text */
  vertical-align: middle;
}

.color__media p:nth-child(1)::before {
  background-color: orange;
}

.color__media p:nth-child(2)::before {
  background-color: pink;
}

.color__media p:nth-child(3)::before {
  background-color: purple;
}

.color__engagement {
  display: flex;
  gap: 1rem;
}
.color__engagement p {
  margin: 0;
  font-size: 12px;
}

.color__engagement p::before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 10px; /* Size of the square */
  height: 10px; /* Size of the square */
  margin-right: 5px; /* Space between the square and the text */
  vertical-align: middle;
}

.color__engagement p:nth-child(1)::before {
  background-color: rgb(227, 109, 99);
}

.color__engagement p:nth-child(2)::before {
  background-color: rgb(73, 167, 64);
}
.likes-bar,
.comments-bar {
  display: inline-block;
  height: 100%;
  position: relative;
}

.likes-bar {
  height: 10px;
  background-color: rgb(227, 109, 99);
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.comments-bar {
  height: 10px;
  background-color: rgb(73, 167, 64);
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.header {
  align-items: flex-start;
  background: #1a1b1c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 2%;
}
.header select {
  height: 20px;
}
.GH__sigil p {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
}

.GH__sigil {
  font-size: 28px;
  font-weight: 300;
  height: 70px;
}

.GH__sigil img {
  height: 50px;
}

.GH__sigil h4 {
  font-size: 40px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  color: grey;
  margin-left: 6px;
  display: flex;
  align-items: flex-end;
}

.drop__down {
  height: 30px;
  border: none;
  color: #757575;
}

.custom-select {
  -webkit-appearance: none; /* Removes default Chrome and Safari style */
  -moz-appearance: none; /* Removes default Firefox style */
  appearance: none; /* Removes default style for other browsers */
  background: url("https://i.postimg.cc/CdnGVtQY/down-arrow-white.png")
    no-repeat;
  background-position: right 10px top 50%; /* Position of custom arrow */
  background-size: 12%;
  padding-right: 30px; /* Space for custom arrow */
  border: none;
  padding-left: 1%;
  font-weight: 700;
  cursor: pointer;
}

.custom-select select:focus {
  outline: none;
  border-radius: 0; /* Removes rounding of corners */
  box-shadow: none; /* Removes any shadow effects */
  background-color: #eeeeee;
}

.footer__select {
  -webkit-appearance: none; /* Removes default Chrome and Safari style */
  -moz-appearance: none; /* Removes default Firefox style */
  appearance: none; /* Removes default style for other browsers */
  background: url("https://i.postimg.cc/CdnGVtQY/down-arrow-white.png")
    no-repeat;
  background-position: right 10px top 50%; /* Position of custom arrow */
  background-size: 6%;
  padding-right: 35px;
  border: none;
  border-bottom: 1px solid white;
  padding-left: 1%;
  font-weight: 700;
  font-size: 16px;
}
.footer__select select:focus {
  outline: none;
  border-radius: 0; /* Removes rounding of corners */
  box-shadow: none; /* Removes any shadow effects */
  background-color: #eeeeee;
}
.footer h2 {
  line-height: 40px;
}

.footer {
  background: rgb(39 38 38);
  color: white;
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.footer__button {
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
  width: 200px;
  transition: transform 0.2s ease;
}

.footer__button button {
  height: 60px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  transition: transform 0.2s ease;
  border-radius: 5px;
  font-size: 20px;
  line-height: 50px;
  letter-spacing: -1.5px;
  font-weight: 700;
  border: white solid 3px;
  border-radius: 2px;
  width: 100%;
}

.smooth-textarea {
  width: 100%;
  padding: 2%;
  height: 120px;
  font-size: 16px;
  transition: opacity 0.5s, max-height 0.5s;
  overflow: hidden;
}

.footer__select:focus + .smooth-textarea,
.footer__select:active + .smooth-textarea,
.smooth-textarea:focus,
.smooth-textarea:active {
  opacity: 1;
}

.grow__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grow__animation {
  padding-right: 2%;
}

.box {
  width: 200px;
  height: 50px;
  position: relative;
  transform-style: preserve-3d;
  animation: flip 4s infinite linear;
}

.face {
  position: absolute;
  width: 200px;
  height: 50px;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.face h1 {
  font-size: 45px !important;
}

.front {
  background: rgb(39, 38, 38);
  transform: translateZ(25px);
}
.back {
  background: rgb(39, 38, 38);
  transform: rotateX(180deg) translateZ(25px);
}
.left {
  background: rgb(39, 38, 38);
  transform: rotateY(-90deg) translateZ(25px);
}
.right {
  background: rgb(39, 38, 38);
  transform: rotateY(90deg) translateZ(25px);
}
.top {
  background: rgb(39, 38, 38);
  transform: rotateX(90deg) translateZ(25px);
}
.bottom {
  background: rgb(39, 38, 38);
  transform: rotateX(-90deg) translateZ(25px);
}

@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.ai__container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 2%;
  background: #cedfe2;
  border-radius: 5px;
}
.ai__container p {
  margin: 0;
  color: rgb(61, 60, 60);
}

.ai__container h4 {
  margin: 0;
  color: #124d51;
}

.ai__content {
  margin-top: 8px;
}

.split {
  color: grey;
}
.split__table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.split__table p {
  margin: 0;
}

.split__account {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .avg__reels {
  background:purple;
  color: white;
  font-weight: 500;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
} */

.pillar-container {
  position: relative;
  height: 100px; /* or any other fixed height */
  width: 100%; /* or the width you prefer for the bars */
}

.pillar {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: height 0.3s ease-out;
  padding-bottom: 4%;
}

.avg__username {
  font-size: 8px;
}

.avg__reels {
  /* writing-mode: vertical-rl;  */
  text-orientation: mixed;
  padding: 2%;
  color: white;
  background: rgba(166, 166, 166, 0.563);
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 300px;
  border: 1px solid #ccc;
  padding: 10px;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
}

.chat__div {
  background: #343541;
  padding: 4%;
  line-height: 2rem;
  border-radius: 10px;
  margin-top: 4%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading__container {
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.loading__img {
  width: 50px;
  height: 50px;
}

.loading-spinner__img {
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading-spinner p {
  margin-top: 10px;
  font-size: 16px;
  color: #333; /* Customize color as needed */
}

.chat__img__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chat__powered {
  width: 10%;
}

.follower__slider {
  margin-top: 15px;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 60%; /* Full-width */
  height: 5px; /* Specified height */
  background: linear-gradient(
    93deg,
    #b7a8fc88,
    #bcf8ea71,
    #d9efdc7c,
    #b7a8fc88,
    #e7c5e780,
    #bce2ff8e
  );
  border-radius: 10px;
  outline: none; /* Remove outline */
}

.follower__slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/rocket.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.follower__slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: url("../../public/images.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer; /* Cursor on hover */
}

.goal__container {
  margin-top: 1%;
  background: #ffffffb1;
  padding: 3%;
  border-radius: 4px;
  color: rgb(71, 71, 71);
  box-shadow: #256b704d 1px 1px 20px 0px;
}

.growth__goal__div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.growth__rate {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: space-evenly;
}
.growth__rate p {
  margin: 0;
  text-decoration: underline;
}

.rate-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rate-container small {
  line-height: 20px;
}

.rate__description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.rate-container h4 {
  margin: 0;
}

.growth__goal__button {
  background-color: rgba(0, 138, 124, 0.655);
  padding: 1%;
  margin: 1%;
  border-radius: 4px;
  width: 90px;
  border: 4px solid white;
}
.selected-growth {
  background-color: rgba(2, 98, 89, 0.655);
}

.growth__goal__button:hover {
  transform: scale(0.95);
}

.monthly__goal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
}

.follower__input {
  border: 4px solid white;
  background-color: #124d51;
  padding: 2%;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  width: 100%;
}

.growth__grid {
  display: grid;
  grid-template-columns: 20% 80%;
}

.growth__goal__input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reach-growth-bar-container {
  height: 100%; /* This should fill the height of the grid area it is placed in */
  display: flex;
  justify-content: center;
}
.legends {
  margin-top: 2%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust the spacing as needed */
}

.legend-color {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Adjust the spacing as needed */
}

.reach__span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-growth {
  background-color: #bcb1f8; /* Replace with the actual color for current growth */
}

.goal-growth {
  background-color: #f1c40f; /* Replace with the actual color for goal growth */
}

.growth__goal__results {
  padding-left: 2%;
}

.goal__chart__grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* or however you have set up your grid */
  grid-template-rows: auto; /* This will ensure the rows size to their content */
  gap: 16px;
  border: 2px solid white;
  padding: 10px;
}
.goal__chart__grid h2 {
  text-align: center;
}

.reach-growth-bar-grid-item {
  /* Set to the grid item that contains the ReachGrowthBar */
  height: 200px; /* Or however tall you want it to be */
}

.calculate__button {
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
  width: 100%;
}
.calculate__button button {
  height: 60px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 30px;
  transition: transform 0.2s ease;
  border-radius: 5px;
  line-height: 50px;
  letter-spacing: -4.5px;
  font-weight: 700;
  border: white solid 4px;
  border-radius: 4px;
  width: 100%;
}

.calculate__button button:hover {
  transform: scale(0.98);
}
.calculate__button p {
  color: #c7d8dd;
}
.competitors__form {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.growth__goal__div p {
  margin: 0;
  font-size: 14px;
  text-align: left;
}
.follower__input::placeholder {
  color: rgba(255, 255, 255, 0.76);
}

.login-modal {
  position: fixed;
  z-index: 1050; /* Higher z-index to ensure it's above other content */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal content vertically and horizontally */
  overflow: auto;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Slightly darker background for better contrast */
  font-family: "Lato", sans-serif; /* From NavBar.css */
}

.login-modal-content {
  background-color: #fefefe;
  margin: auto; /* Center the modal content box */
  padding: 80px; /* Increased padding for better spacing */
  border: 1px solid #888;
  width: 90%; /* Responsive width */
  max-width: 500px; /* Maximum width to ensure it looks good on larger screens */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 8px; /* Rounded corners */
  position: relative; /* For positioning the close button absolutely within this container */
  color: #eceff1; /* Light grey text color from NavBar.css */
  background-color: #263238; /* Dark slate grey background from NavBar.css */
  border: none; /* Remove border */
}
.login-modal-terms a {
  color: #b7b7b7;
  text-decoration: underline;
}

.login-modal-header {
  text-align: center;
  margin-bottom: 20px;
}
.login-modal-header h2 {
  color: white;
}

/* Style for the input fields */
.login-modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #888; /* Slightly visible border */
  background-color: #eceff1; /* Light grey background */
  color: #263238; /* Dark text for input */
}

/* Style for the primary action button */
.login-modal-action-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: none;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  color: #263238;
  cursor: pointer;
}

.login-modal-secondary-action-button {
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  border: 1px solid #cccccc; /* Light grey border */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in the element's width */
  transition: background-color 0.3s ease;
}

.login-modal-secondary-action-button:hover {
  background-color: #f5f5f5; /* Slightly darker grey on hover */
}

.login-button-logo {
  margin-right: 10px; /* Space between logo and text */
  width: 25px; /* Logo width */
  height: 25px; /* Logo height */
}

.close {
  color: #aaa;
  position: absolute; /* Position it relative to its parent */
  top: 10px;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
}

.login-facebook {
  background-color: #1a77f2;
  color: white;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  transition: background-color 0.2s;
  gap: 2rem;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.login-google {
  background-color: #f2f2f2;
  color: black;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  display: flex;
  text-align: center;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  transition: background-color 0.2s;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

.login-facebook:hover {
  background-color: #365899;
}
.login-google:hover {
  background-color: #e5e5e5;
  color: black;
}
/* Style for the separator */
.login-modal-separator {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.login-modal-separator::before,
.login-modal-separator::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #888;
}

.login-modal-separator::before {
  left: 0;
}

.login-modal-separator::after {
  right: 0;
}

.login-modal-separator-text {
  display: inline-block;
  background-color: #263238; /* Background from NavBar.css */
  padding: 0 10px;
  color: #eceff1; /* Text color from NavBar.css */
}

/* Style for the terms text */
.login-modal-terms {
  font-size: 12px;
  color: #b7b7b7; /* Grey text color from NavBar.css */
  text-align: center;
}

@media screen and (min-width: 768px) {
  .competitor__form img {
    width: 3%;
  }
  .competitors__form img {
    width: 3%;
  }
  .avg__username {
    font-size: 12px;
  }
  .competitor-comparison {
    margin-bottom: 4%;
  }
  .username__td {
    width: 16%;
  }
  .followers__td {
    width: 11%;
  }
  .hero {
    margin-top: 1%;
  }

  .photos {
    height: 15px;
  }

  .carousels {
    height: 15px;
  }
  .reels {
    height: 15px;
  }
  .likes-bar {
    height: 15px;
  }
  .comments-bar {
    height: 15px;
  }
  .competitor-comparison .bar {
    height: 15px;
  }

  .footer {
    gap: 0 !important;
  }
  .get__button {
    width: 30%;
  }
  .multiplier__selector {
    flex-direction: row;
  }
}
