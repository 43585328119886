.dashboard {
  color: #141414;
  padding: 1% 1% 1% 1%;
  height: 150vh;
}

.goal-grid {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* This will make sure there are always 2 items per row */
  gap: 20px; /* Space between cards */
  padding: 20px; /* Padding around the grid */
}

.goal-card {
  background: #ffffff; /* Set to your preferred card background color */
  border: 1px solid #e1e1e1; /* Optional border for cards */
  border-radius: 10px; /* Optional rounded corners for cards */
  padding: 20px; /* Padding inside each card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for cards */
  height: 430px; /* Fixed height for cards */
  overflow: hidden; /* Ensures the content does not overflow the card */
  cursor: pointer;
}

.add-goal-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.add-goal-card img {
  width: 10%;
}
.add-goal-card:hover {
  transform: scale(1.02);
}

.close__p {
  text-align: right;
  margin: 0;
  cursor: pointer;
}

.add-goal-card p {
  background: #cecece;
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.goal-inline-grid {
  display: grid;
  grid-template-columns: 70% 30%;
  height: 100%;
}
.goal-inline-grid > div:last-child {
  grid-column: 1 / -1; /* Span from the first to the last column */
}

.goal-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.goal-right input {
  width: 100%;
  height: 30px;
}
.goal-right select {
  width: 100%;
  height: 30px;
}

.goal-stats-grid {
  display: grid;
  gap: 0.3rem;
}

.active {
  position: fixed;
  z-index: 1050; /* Above other content */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Or any size you prefer */
  height: 80%; /* Or any size you prefer */
  background-color: white; /* Or any color */
}

.goal-card-backdrop {
  display: none; /* Hidden by default */
}

.goal-card-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(205, 205, 205, 0.838);
  z-index: 1040; /* Below the active goal card */
  display: block; /* Show backdrop when a goal card is active */
}

.status-goal-p {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.goal-stats-grid h4 {
  margin: 0;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.goal-media {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.progress-p {
  margin: 0;
  font-size: 18px;
}

.progress-d {
  margin: 0;
  margin-top: 2px;
  font-size: 12px;
}

.goal-container select {
  margin-bottom: 4px;
  font-size: 10px;
}
.goal-container input {
  margin-bottom: 4px;
  font-size: 10px;
}
.goal-values h3 {
  margin-top: 6px;
  font-size: 35px;
  margin: 0 0 12px;
}

.goal-values svg {
  margin-right: 2%;
}

.set-goal-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.2rem;
}
.set-goal-btn {
  background: green;
  border-radius: 4px;
  height: 80%;
  color: white;
}

.set-goal-header select,
.set-goal-header input {
  font-size: 10px;
  width: 100%;
}

.goal-grid-content {
  background: rgb(32 53 76);
  height: fit-content;
  padding: 4%;
  color: white;
  border-radius: 4px;
}

.goal-comp {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.2rem;
}
.growth__p {
  font-size: 12px !important;
}
.comp-growth {
  display: flex;
  justify-content: flex-start;
  align-content: flex-end;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 5px;
}
.goal-comp-content {
  padding: 4%;
  background: rgb(32 53 76);
  border-radius: 4px;
}
.goal-comp-content h2 {
  margin: 0;
  font-size: 14px;
  color: white;
  margin-top: 4px;
}
.goal-comp-content p {
  margin: 0;
  font-size: 16px;
  color: white;
  margin-top: 4px;
}

.add-goal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goal-progress-bar {
  margin-top: 15px;
}

.mtd-goal {
  display: grid;
  font-size: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5px;
  margin: 0px 0px 19px 0px;
  align-items: end;
  justify-items: start;
}

.mtd-goal p {
  margin: 0;
  font-size: 15px;
}
.mtd-goal h2 {
  margin: 0;
  color: white;
  margin-top: 4px;
}
