/* Define root-level variables */

* {
  font-family: Inter, sans-serif;
  letter-spacing: -0.5px;
}

input,
select,
textarea {
  /* or larger */
  cursor: pointer;
  transition: all 0.2s ease;
}

input:hover {
  transform: scale(0.99);
}
h1 {
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  line-height: 50px;
  letter-spacing: -4.5px;
}

/* Base element styles */
html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  color: white;
}

select {
  color: white !important;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  color: rgb(85 85 85);
}

h3 {
  color: rgb(85 85 85);
}

h4 {
  color: rgb(85 85 85);
}

/* Buttons */
button {
  font: inherit;
  cursor: pointer;
}

a,
button,
.button,
input[type="submit"],
input[type="button"],
select {
  color: inherit; /* Replace with the color you want */
  text-decoration: none; /* Removes underline from links */
}

/* To target <option> tags specifically, which might not always obey the color property: */
option {
  color: inherit; /* Replace with the color you want */
}

/* To ensure that the color applies to the buttons in various states: */
button,
.button,
input[type="submit"],
input[type="button"] {
  background-color: inherit; /* Replace with the background color you want */
  border: none; /* Removes the default border */
}

/* For a hover effect on links and buttons: */
a:hover,
button:hover,
.button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  color: inherit; /* Replace with the color you want on hover */
}

/* You may also want to apply styles for active, focus, and visited states */
a:active,
button:active,
.button:active,
input[type="submit"]:active,
input[type="button"]:active,
a:focus,
button:focus,
.button:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
a:visited {
  color: inherit; /* Replace with the color for these states */
}

tbody {
  font-weight: 500;
  font-size: 15px;
}

.echo-title {
  font-family: monospace;
  letter-spacing: 6px;
  font-size: 24px;
  color: #96f0d9;
}

.echo-subtitle {
  font-size: 14px;
  letter-spacing: 1px;
  color: #96f0d9;
}

.correlation-grid {
  display: grid;
  grid-template-columns: 13% 87%;
  align-items: center;
}
.correlation-values {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 10px 33px 0px 20px;
}

.accuracy-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px;
  width: 140px;
}

.circular-chart {
  height: 100%;
  transform: rotate(0deg);
  width: 100%;
}

.circle-bg {
  fill: none;
  stroke: #333;
  stroke-width: 3.8;
}

.circle-progress {
  fill: none;
  stroke: #96f0d9;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.percentage {
  position: absolute;
  font-size: 32px;
  font-weight: 400;
  color: #96f0d9;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.percentage-label {
  left: 50%;
  position: absolute;
  top: 62%;
  transform: translate(-50%, -50%);
  color: #ddd;
}
.value-label {
  margin-top: 5px;
  color: #ddd;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 11px;
}

.value-box {
  background: #29292b;
  border-radius: 20px;
  box-shadow: 0 4px 8px #0000004d;
  padding: 15px;
  height: 145px;
  width: 160px;
}

.value-box .value {
  font-size: 38px;
  color: #96f0d9;
}

.value-box .label {
  margin-top: 5px;
  color: #ddd;
  font-size: 14px;
}

.comments-list {
  color: black;
}
.comment-item {
  width: fit-content;
  padding: 1%;
  background: #c0c0c0;
  border-radius: 20px;
  margin-bottom: 10px;
}

.customer-journey-result-header {
  display: flex;
  gap: 2rem;
}

.customer-journey-form {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.customer-journey-form button {
  background: #fff09d;
  border-radius: 20px;
  color: black;
}
.customer-journey-form select {
  color: black !important;
}

.customer-journey-result {
  width: 100%;
  padding: 10px 0px 10px 0px;
}

.customer-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  background: #f9f9f9;
}

.customer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  background: #ececec;
  border-radius: 5px;
}

.customer-id {
  color: #333;
  font-size: 14px;
}

.time-to-checkout {
  color: #555;
  font-size: 12px;
}

.toggle-arrow {
  font-size: 16px;
  color: #666;
}

.customer-actions {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  color: #333;
}

.customer-actions li {
  padding: 8px 5px;
  border-bottom: 1px solid #ddd;
}

.customer-actions li:last-child {
  border-bottom: none;
}

.customer-actions strong {
  color: #333;
}

.customer-actions em {
  color: #555;
  font-style: italic;
  font-size: 12px;
}

.button-with-spinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fetch-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #e68e01;
  height: 13px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Luzmo {
  background-color: #141414;
}

.luzmo-filter-select {
  margin: 10px 30px;
  background: #787878;
  border-radius: 5px;
  color: white;
}

.dashboard {
  background: #141414;
  color: white;
  height: 150vh;
}

.app-dashboard-integration-bar {
  display: none !important;
}

.integration-bar {
  display: none !important;
}

.ng-tns-c2267794089-0 {
  display: none !important;
}

.seed__script-container {
  background: #282c34;
  border-radius: 5px;
  margin-top: 1em;
  padding: 0% 8% 0% 1%;
  position: relative;
  width: fit-content;
}

.seed__script-code {
  color: #abb2bf; /* Light grey color for the text */
  font-family: "Fira Code", "Consolas", "Monaco", monospace; /* Monospaced font for better code readability */
  white-space: pre-wrap; /* Preserves spaces and line breaks */
  word-break: break-all; /* Ensures long lines do not break the layout */
  font-size: 0.9em; /* Adjust font size to your preference */
  line-height: 1.5em;
}

.seed__copy-button {
  background: #a5d6a7;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  padding: 0.5em;
  position: absolute;
  right: 1em;
  top: 1em;
}

.seed__copy-button:hover {
  background: #779b78; /* Darker blue on hover */
}

.seed__copy-button:active {
  background: #779b78; /* Even darker blue when pressed */
}

/* Styles for the success/error message */
.copy-feedback {
  color: #a5d6a7; /* Green color for success */
  /* Position it however you like, for example: */
  position: absolute;
  bottom: -20px;
  left: 1em;
}

.copy-feedback.error {
  color: #e06c75; /* Red color for error */
}

.customize__fixed__header {
  position: fixed;
  top: 0;
  left: 6%;
  right: 1%; /* Ensures it covers the entire screen width from the left offset */
  z-index: 1000;
  padding-top: 0.7rem;
}

.available__data {
  margin-top: 1%;
  border: 5px solid #efefef;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-header th {
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.data-row td {
  padding: 2px 0px 4px 10px;
  border-bottom: 1px solid #ddd;
}
.data-row {
  background-color: white;
}
.data-row:nth-child(even) {
  background-color: #f9f9f9;
}

.data-row td input[type="checkbox"] {
  margin-left: 10px; /* Centers the checkbox in the cell */
}

.fetch__data__form {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.fetch__data__form select {
  border: none;
  border-bottom: 6px solid #ffd400;
  font-size: 13px;
  background: #ffe66a;
  padding-top: 0px;
  color: black !important;
  width: 90px;
}
.fetch__data__form button {
  background: #fff09d;
  border-radius: 20px;
  padding-left: 17px;
  padding-right: 17px;
  width: 160px;
  color: black;
  border: 4px solid #f1cb07;
  transition: all 0.2s ease;
}
.upload__button {
  background: #fff09d;
  border-radius: 20px;
  padding-left: 17px;
  padding-right: 17px;
  width: 200px;
  border: 4px solid #f1cb07;
  transition: all 0.2s ease;
}
.fetch__data__form button:hover {
  background: #fff6c6c2;
  color: rgb(88, 88, 88);
  transform: scale(0.98);
}

.privileges {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}
.bin {
  cursor: pointer;
}
.bin:hover {
  transform: scale(0.98);
}
.customize__chart {
  padding: 2%;
  margin-top: 100px;
}
.customize__chart__filter {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 2rem;
  align-items: center;
  padding-right: 1%;
  padding-top: 4px;
}

.chart__style {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.insights__fields {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}

.dash--btn {
  border: 3px solid white;
  border-radius: 2px;
  transition: all 0.2s ease;
}
.dash--btn:hover {
  color: #2b7075;
  background: white;
  transform: scale(0.98);
}
.bar {
  height: 20px;
  background-color: #007aff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.organic__module {
  padding: 6%;
}

.organic__module h2 {
  margin: 0;
  color: black;
}

.organic__engine__overview {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin-top: 5%;
}

.bar__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.privileges__accounts {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.privileges__accounts img {
  cursor: pointer;
  width: 4%;
  margin-right: 4%;
}

.privileges__accounts img:hover {
  transform: scale(1.12);
}

.privacy__div p {
  margin-bottom: 20px;
  line-height: 2rem;
}

.privacy__div h4 {
  margin-bottom: 10px;
}

.privacy__div ul {
  margin-bottom: 20px;
  line-height: 2rem;
}

.get__button button:disabled {
  cursor: default;
}
.g {
  cursor: pointer;
}

.scorecard__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.scorecard__box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.2rem;
  column-gap: 1rem;
  width: 100%;
}

.box__content {
  padding: 10% 10% 10% 10%;
  border-radius: 10px;
  background-color: #092240;
  color: grey;
  width: 100%;
}

.box__content__icons {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 1rem;
}
.box__content__icons svg {
  font-size: 17px;
  color: white;
}
.box__content__icons p {
  color: white;
  margin: 0;
}
.scorecard__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.box__growth {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box__growth p {
  font-weight: 700;
  font-size: 12px;
}

.box__content h2 {
  margin: 0;
  color: rgba(255, 255, 255, 0.741);
  font-size: 12px;
}

.box__content h4 {
  margin: 0;
  padding-top: 4%;
  color: rgb(255, 255, 255);
  font-size: 19px;
}

.add__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.add__button img {
  width: 4%;
}
.add__button p {
  display: flex;
  margin: 0;
}
.get__button {
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
  width: 100%;
}
.get__button button {
  height: 60px;
  background: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  transition: transform 0.2s ease;
  border-radius: 5px;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -4.5px;
  font-weight: 700;
  border: white solid 4px;
  border-radius: 4px;
  width: 100%;
}

.get__button button:hover {
  transform: scale(0.98);
}
.get__button p {
  color: #c7d8dd;
}

/* Links */

/* Utility classes */
.text-center {
  text-align: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(30, 30, 30);
}

.login__button {
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  background-color: #414141; /* Dark gray background */
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  padding: 15px 30px;
  font-size: var(--font-size-medium);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(69, 45, 89, 0.218);
  border: 2px solid transparent;
  overflow: hidden; /* Ensure the pseudo-element is clipped to the button's border-radius */
  transition: color 1s ease-in-out, box-shadow 2s ease-in-out,
    border-color 2s ease-in-out; /* Transition for text color and shadow */
}

.login__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    93deg,
    #b8a8fc,
    #bcf8ea,
    #d9efdc,
    #b8a8fc,
    #e7c5e7,
    #bce2ff
  );
  border-radius: inherit; /* Ensure it matches the button's border-radius */
  opacity: 0;
  transition: opacity 2s ease-in-out; /* Transition for the pseudo-element's opacity */
}

.login__button:hover::after,
.login__button:focus::after {
  opacity: 1; /* Show the gradient on hover/focus */
}

.login__button:hover,
.login__button:focus {
  color: #414141;
  box-shadow: 0 6px 12px rgba(191, 224, 250, 0.5);
  border-color: #ffffff;
}

/* Pop-up background (overlay) */
.popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures that the overlay is on top of other content */
}

/* Pop-up window */
.popup {
  background-color: #111111f2;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 90%;
}

/* Pop-up message */
.popup p {
  margin-top: 0;
  font-size: 20px;
  color: white;
  line-height: 2rem;
}

.popup-container {
  position: relative;
  /* Other styling for the popup container */
}

.close-button {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  /* Additional styling for the button */
}

/* Style for the popup overlay */
.chart-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}

/* Style for the popup content */
.chart-popup > div {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

/* Style for the image container */
.chart-popup img {
  width: 100%;
  object-fit: cover; /* Ensure the image covers the area */
  grid-column: 1 / 2; /* Place it in the first column */
}

/* Style for the text content next to the image */
.chart-popup > div > div:first-child {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* Style for the data container */
.chart-popup > div > div:last-child {
  padding: 10px;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chart-popup > div {
    grid-template-columns: 1fr; /* Stack image and data vertically on smaller screens */
  }

  .chart-popup img {
    grid-row: 1; /* Ensure image is at the top */
  }
}

.footer {
  padding: 2%;
  background: rgb(0, 0, 0);
  color: white;
}

.footer__logo {
  max-width: 100px; /* Use max-width for scalability */
}

.footer__logo img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.footer__first {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  gap: 2rem; /* Reduced gap for smaller screens */
}

.footer__links {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.footer__links a {
  font-size: 1.25rem; /* Relative font size */
  line-height: 1.5; /* Relative line height */
}

.footer__second {
  margin-top: 4%;
  padding-top: 4%;
  border-top: 1px solid grey;
  display: flex;
  flex-direction: column; /* Stack elements for mobile */
  gap: 2rem; /* Add some space between stacked elements */
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center social icons */
  gap: 1rem; /* Reduce gap for smaller screens */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer__first,
  .footer__second {
    flex-direction: column;
    align-items: start;
    padding: 4%;
  }

  .footer__links {
    align-items: start; /* Center-align the links */
  }

  .footer__links a {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .socials {
    justify-content: start; /* Center social icons on mobile */
    flex-direction: column;
  }
}

/* Close button inside the popup */
.integrations {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.integration-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.integration-box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  background: #263238;
  padding: 2%;
  border-radius: 8px;
}

.integration-box img {
  height: 50px;
}
.integration-box button {
  background: #a5d6a7;
  border-radius: 20px;
  padding-left: 17px;
  padding-right: 17px;
  width: 160px;
  border: 4px solid #7ca27e;
  transition: all 0.2s ease;
}
.integration-box button:hover {
  background: #7ca27e;
  color: rgb(88, 88, 88);
  transform: scale(0.98);
}
