/* NavBar.css adjustments for consistent icon sizing and positioning */

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #263238; /* Consistent dark slate grey for a rich backdrop */
  color: #eceff1; /* Light grey for subtle contrast */
  width: 80px; /* Set to the width of the logo for a compact look */
  box-sizing: border-box;
  position: fixed;
  font-family: "Lato", sans-serif; /* Modern and clean font */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  padding: 20px 10px; /* Adjust padding to center the icons */
  transition: width 0.3s ease; /* Smooth transition for expanding width */
  z-index: 5000;
}

.navbar:hover {
  width: 260px; /* Expand the navbar on hover to show text */
}

/* Hide text when navbar is not hovered */
.nav-item span,
.gh-nav span {
  margin-left: 15px;
  display: none;
  white-space: nowrap; /* Ensure the text does not wrap */
}

.user-email {
  font-size: 14px; /* Appropriate font size for the email */
  color: #eceff1; /* Light grey for subtle contrast */
}

/* Show text when navbar is hovered */
.navbar:hover .nav-item span,
.navbar:hover .gh-nav span {
  display: inline;
}

/* Adjustments for the user profile to only show the logout icon initially */
.user-profile {
  justify-content: center; /* Center the logout icon */
  padding: 15px 20px;
}

/* Show user info on hover */
.navbar:hover .user-profile {
  justify-content: space-between; /* Re-align to show the user info and logout icon */
}

.user-info {
  display: none; /* Hide user info initially */
}

.navbar:hover .user-info {
  display: block; /* Show user info on hover */
}

.nav-items {
  padding: 0; /* Remove padding for precise alignment of items */
}

.nav-item,
.gh-nav {
  display: flex;
  align-items: center;
  padding: 15px 20px; /* Padding for a plush feel */
  margin: 5px 0; /* Adjusted margin for tighter layout */
  text-decoration: none;
  color: inherit;
  font-size: 18px; /* Font size for readability */
}

.nav-item svg,
.gh-nav svg {
  width: 23px; /* Icon size for clarity */
  height: 23px;
  flex-shrink: 0;
}

.nav-item:hover,
.gh-nav:hover {
  background-color: #37474f; /* Hover state color */
  color: #a5d6a7; /* Highlight color */
  cursor: pointer;
}
.navbar-top {
  font-size: 20px;
}
.nav-item-active {
  background-color: #a5d6a7; /* Active state color */
  color: #263238; /* Text color for contrast */
  font-weight: bold;
}

/* Additional styling for hover effects on logout icon */
.navbar-bottom a:hover {
  transform: scale(1.1); /* Slight scale for interaction */
  transition: transform 0.2s ease;
}
